// GlitchImage.tsx
import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useTexture } from '@react-three/drei';
import { EffectComposer, Glitch } from '@react-three/postprocessing';
import { GlitchMode } from 'postprocessing';
import { Vector2 } from 'three';

interface GlitchImageProps {
  imageUrl: string;
}

const ImagePlane: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  const texture = useTexture(imageUrl);

  return (
    <mesh>
      {/* Use CircleGeometry to create a circular shape */}
      <circleGeometry args={[2.5, 64]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
};

const GlitchImage: React.FC<GlitchImageProps> = ({ imageUrl }) => {
  return (
    <Canvas style={{width: "80%"}}>
      {/* Lighting */}
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />

      {/* Image Plane */}
      <ImagePlane imageUrl={imageUrl} />

      {/* Glitch Effect */}
      <EffectComposer>
        <Glitch
          mode={GlitchMode.SPORADIC} // Glitch mode
          delay={new Vector2(3.5, 5)} // Min and max glitch delay
          duration={new Vector2(0.6, 1.0)} // Min and max glitch duration
          strength={new Vector2(0.3, 1.0)} // Weak and strong glitch strength
        />
      </EffectComposer>

      {/* Controls */}
      <OrbitControls />
    </Canvas>
  );
};

export default GlitchImage;
