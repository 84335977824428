import React, { useEffect, useState } from 'react';

interface LinkProps {
    anchorLinkId: string;
    link: string;
    imageSrc?: string;
    text: string;
    category?: string;
}

const Link: React.FC<LinkProps> = ({ link, imageSrc, text, category, anchorLinkId }) => {
    const [hovered, setHovered] = useState(false);
    const [deepLinked, setDeepLinked] = useState(false);

    const handleMouseEnter = () => setHovered(true);
    const handleMouseLeave = () => setHovered(false);

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.substring(1);
            if (hash === anchorLinkId) {
                setDeepLinked(true);
            }
            else {
                setDeepLinked(false);
            }
        };

        // Listen to changes in the URL hash
        window.addEventListener('hashchange', handleHashChange, false);

        // Scroll on initial load if there's a hash
        handleHashChange();

        // Cleanup event listener
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, []);

    return (
        <div className='flex flex-col items-end'>
            <div
                id={anchorLinkId}
                className={` w-full rounded-lg overflow-hidden bg-slate-950 transition-transform duration-300 
        ${(hovered || deepLinked) ? 'scale-105 neon-border-hover' : 'scale-100 neon-border'}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full relative"
                >
                    {imageSrc && (
                        <img
                            src={imageSrc}
                            alt=""
                            className="w-full h-40 object-cover opacity-70 hover:opacity-100 transition"
                        />
                    )}
                    <div className="p-3 flex flex-col items-start">
                        {category && (
                            <div className="text-xs text-emerald-300 mb-1">
                                {category}
                            </div>
                        )}
                        <p className="text-white text-sm line-clamp-2">{text}</p>
                    </div>
                    {/* Permanent Glow Layer */}
                    <div className="absolute inset-0 neon-glow pulse-slow pointer-events-none" />
                    {/* Intensified Glow on Hover */}
                    {hovered && (
                        <div className="absolute inset-0 neon-glow-hover pointer-events-none" />
                    )}
                </a>
            </div>
            <div>
                {
                    anchorLinkId && (
                        <a
                            href={`#${anchorLinkId}`}
                            className="p-2 text-xs text-slate-700 transition text-right w-full"
                        >
                            #
                        </a>
                    )
                }
            </div></div>
    );
};

// CSS for neon glow, slow pulsing effect, and intensified hover effect
const styles = `
.neon-border {
  border: 2px solid rgba(58, 134, 255, 0.3); /* Soft neon blue border */
  box-shadow: 0 0 8px rgba(58, 134, 255, 0.3);
}

.neon-border-hover {
  border-color: rgba(58, 134, 255, 0.5);
  box-shadow: 0 0 12px rgba(58, 134, 255, 0.6), 0 0 16px rgba(58, 134, 255, 0.5);
}

.neon-glow {
  background: rgba(58, 134, 255, 0.1);
  border-radius: inherit;
  box-shadow: 0 0 10px 4px rgba(58, 134, 255, 0.3), 0 0 20px 8px rgba(58, 134, 255, 0.2);
}

.pulse-slow {
  animation: pulse 5s infinite alternate;
}

.neon-glow-hover {
  animation: flicker 1.5s infinite alternate;
  background: rgba(58, 134, 255, 0.2);
  border-radius: inherit;
  box-shadow: 0 0 12px 6px rgba(58, 134, 255, 0.7), 0 0 24px 12px rgba(58, 134, 255, 0.5);
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes flicker {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
}
`;

// Injecting custom CSS
const styleElement = document.createElement("style");
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);

export default Link;
