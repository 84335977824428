import { faGithub, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import GlitchImage from './components/GlitchImage';
import Link from './components/Link';


function App() {

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        window.scrollBy(0, -50)
      }
    };

    // Listen to changes in the URL hash
    window.addEventListener('hashchange', handleHashChange, false);

    // Scroll on initial load if there's a hash
    handleHashChange();

    // Cleanup event listener
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-slate-900 text-white font-sans">
      <div className="flex flex-col items-center w-full max-w-md p-4 my-8">

        {/* Profile Section */}
        <div className="flex flex-col items-center mb-8 space-y-3">

          <GlitchImage imageUrl={`${process.env.PUBLIC_URL}/avatar.jpg`} />

          <div>
            <h1 className="text-4xl font-serif mb-1 font-bold text-center">longing to it</h1>
            <h2 className="text-base text-center">Works by Francis Szakacs</h2>
          </div>

          <p className="text-gray-400 text-center font-serif">“I heard an owl calling, from the Platt Fields. Real, Vurt, or robo – who can tell the difference any more? No matter. <span className='text-pink-600'>It had a longing to it.</span>” (Jeff Noon, Vurt)</p>

          {/* Social Links */}
          <div className="flex space-x-4 pt-2">
            <a href="https://www.instagram.com/francis_szakacs/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-2xl" />
            </a>
            <a href="https://www.youtube.com/@szili" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} className="text-2xl" />
            </a>
            <a href="https://x.com/francis_systems" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} className="text-2xl" />
            </a>
            <a href="https://www.linkedin.com/in/francisszakacs/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="text-2xl" />
            </a>
            <a href="https://github.com/thefranciscook" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} className="text-2xl" />
            </a>
          </div>

        </div>

        {/* Links Section */}
        <div className="space-y-2 w-full">

          <Link
            anchorLinkId='cobralingus'
            link="https://chatgpt.com/g/g-67321d778304819097f850cd911299e4-cobralingus-engine"
            imageSrc={`${process.env.PUBLIC_URL}/gpt2.png`}
            category='GPT'
            text="Cobralingus Engine, a trippy creative writing process inspired by Jeff Noon"
          />

          <Link
            anchorLinkId='robotArm'
            link="https://www.youtube.com/watch?v=2B392ktHd5Q"
            imageSrc={`${process.env.PUBLIC_URL}/experiment1.png`}
            category='Art'
            text="An AI which can only respond by drawing with a robot arm"
          />

          <Link
            anchorLinkId='diagramFromChat'
            link="https://diagram.fromch.at/"
            imageSrc={`${process.env.PUBLIC_URL}/app1.png`}
            category='App'
            text="Diagram from Chat: a tool to draw digrams by chatting with an AI"
          />

          <Link
            anchorLinkId='ideateLive'
            link="https://diagram.fromch.at/"
            imageSrc={`${process.env.PUBLIC_URL}/ideatelive.png`}
            category='App'
            text="IdeateLive, a radically new take on remote brainstorming built on game design and with immedate PowerPoint export."
          />

          <Link
            anchorLinkId='simpleWriter'
            link="https://diagram.fromch.at/"
            imageSrc={`${process.env.PUBLIC_URL}/simplewriter.png`}
            category='GPT'
            text="Simple Writer: applies plain English to complex texts, takes the baroque out of a typical ChatGPT prose."
          />

          {/* Networking Call Link */}
          <a
            href="https://calendly.com/szakacsszilard/personal-30"
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full py-3 px-6 bg-pink-800 rounded-lg text-center font-medium hover:bg-pink-700 transition"
          >
            Like my stuff? Let's hop on a call!
          </a>
        </div>

      </div>
    </div>
  );
}

export default App;
